export default [
    {
        path: '/miscellaneous/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            requiresAuth: true,
        }
    }
]