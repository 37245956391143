import { actions, resources } from '@/libs/acl/resources';

export default [
	{
		path: '/contractors/courses',
		name: 'contractors-courses-list',
		component: () => import('@/views/modules/contractors/courses/courses-list/CoursesList.vue'),
		meta: {
			resource: resources.CONTRACTOR_COURSES,
			pageTitle: 'Servicios',
			breadcrumb: [{
				text: 'Lista de servicios',
				active: true
			}],
			requiresAuth: true
		}
	},
	{
		path: '/contractors/courses/:slug/edit',
		name: 'contractors-courses-edit',
		component: () => import('@/views/modules/contractors/courses/courses-edit/CoursesEdit.vue'),
		meta: {
			resource: resources.CONTRACTOR_COURSES,
			action: actions.UPDATE,
			pageTitle: 'Servicio',
			breadcrumb: [{
				text: 'Lista de servicios',
				to: { name: 'courses-list' },
				active: false
			}, {
				text: 'Editar servicio',
				active: true
			}],
			requiresAuth: true
		}
	}
]
