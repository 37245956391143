import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/students',
        name: 'students-list',
        component: () => import('@/views/modules/essac/students/students-list/StudentsList.vue'),
        meta: {
            resource: resources.ESSAC_STUDENTS,
            pageTitle: 'Personal',
            breadcrumb: [{
                text: 'Lista del personal',
                active: true
            }],
            requiresAuth: true
        }
    }
]
