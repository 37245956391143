import { actions, resources } from '@/libs/acl/resources';

export default [
    {
        path: '/questions',
        name: 'questions-list',
        component: () => import('@/views/modules/essac/questions/questions-list/QuestionsList.vue'),
        meta: {
            resource: resources.ESSAC_QUESTIONS,
            pageTitle: 'Preguntas',
            breadcrumb: [{
                text: 'Lista de preguntas',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/questions/create',
        name: 'questions-create',
        component: () => import ('@/views/modules/essac/questions/questions-store/QuestionsStore.vue'),
        meta: {
            resource: resources.ESSAC_QUESTIONS,
            action: actions.CREATE,
            pageTitle: 'Preguntas',
            breadcrumb: [{
                text: 'Agregar pregunta',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/questions/:id/edit',
        name: 'questions-edit',
        component: () => import ('@/views/modules/essac/questions/questions-edit/QuestionsEdit.vue'),
        meta: {
            resource: resources.ESSAC_QUESTIONS,
            action: actions.UPDATE,
            pageTitle: 'Preguntas',
            breadcrumb: [{
				text: 'Lista de preguntas',
				to: { name: 'questions-list' },
				active: false
			}, {
				text: 'Editar pregunta',
				active: true
			}],
            requiresAuth: true
        }
    }
]