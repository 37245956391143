import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/supervisors',
        name: 'supervisors-list',
        component: () => import('@/views/modules/essac/supervisors/supervisors-list/SupervisorsList.vue'),
        meta: {
            resource: resources.ESSAC_SUPERVISORS_COMPANIES,
            pageTitle: 'Subsidiarias',
            breadcrumb: [{
                text: 'Lista de subsidiarias',
                active: true
            }],
            requiresAuth: true
        }
    }
]
