import { actions, resources } from '@/libs/acl/resources';

export default [
	{
		path: '/students/courses',
		name: 'students-courses-list',
		component: () => import('@/views/modules/students/courses/courses-list/CoursesList.vue'),
		meta: {
			resource: resources.STUDENT_COURSES,
			pageTitle: 'Servicios',
			breadcrumb: [{
				text: 'Lista de servicios',
				active: true
			}],
			requiresAuth: true
		}
	},
	{
		path: '/students/courses/:slug/content',
		name: 'students-courses-content',
		component: () => import('@/views/modules/students/courses/courses-edit/CoursesEdit.vue'),
		meta: {
			resource: resources.STUDENT_COURSES,
			action: actions.UPDATE,
			pageTitle: 'Servicio',
			breadcrumb: [{
				text: 'Lista de servicios',
				to: { name: 'courses-list' },
				active: false
			}, {
				text: 'Contenido servicio',
				active: true
			}],
			requiresAuth: true
		}
	},
	{
		path: '/students/courses/:slug/exams/:examslug/attempt/:attempt/start',
		name: 'students-courses-exams-attempt-start',
		component: () => import('@/views/modules/students/exams/exams-start/ExamsStart.vue'),
		meta: {
			resource: resources.STUDENT_COURSES,
			action: actions.UPDATE,
			pageTitle: 'Examen',
			requiresAuth: true
		}
	}
]
