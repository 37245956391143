// ACL => Access Control List
/**
 * Se debe entender que el resource "all" abarca todo estos valores
 */
export const resources = {
    ACL: 'ACL',
    ESSAC_SUPERVISORS_COMPANIES: 'ESSAC_SUPERVISORS_COMPANIES',
    ESSAC_CONTRACTING_COMPANIES: 'ESSAC_CONTRACTING_COMPANIES',
    ESSAC_QUESTIONS: 'ESSAC_QUESTIONS',
    ESSAC_STUDENTS: 'ESSAC_STUDENTS',
    ESSAC_COURSES: 'ESSAC_COURSES',
    ESSAC_REPORTS: 'ESSAC_REPORTS',

    SUPERVISOR_REPORTS: 'SUPERVISOR_REPORTS',

    CONTRACTOR_STUDENTS: 'CONTRACTOR_STUDENTS',
    CONTRACTOR_COURSES: 'CONTRACTOR_COURSES',
    CONTRACTOR_REPORTS: 'CONTRACTOR_REPORTS',

    STUDENT_COURSES: 'STUDENT_COURSES'
}

/**
 * - Se debe entender que el action "manage" abarca todas estas acciones
 */
export const actions = {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete'
}

export const roles = {
    SUPERADMIN: 'superadmin',
    ESSAC: 'essac',
    SUPERVISOR: 'supervisor',
    CONTRACTOR: 'contractor',
    USER: 'user'
}