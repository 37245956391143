import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/modules/essac/reports/Reports.vue'),
        meta: {
            resource: resources.ESSAC_REPORTS,
            pageTitle: 'Reportes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true
                }
            ],
            requiresAuth: true
        }
    }
]