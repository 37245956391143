import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/supervisors/reports',
        name: 'supervisors-reports',
        component: () => import('@/views/modules/supervisors/reports/Reports.vue'),
        meta: {
            resource: resources.SUPERVISOR_REPORTS,
            pageTitle: 'Reportes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true
                }
            ],
            requiresAuth: true
        }
    }
]