import { actions, resources } from '@/libs/acl/resources';

export default [
	{
		path: '/courses',
		name: 'courses-list',
		component: () => import('@/views/modules/essac/courses/courses-list/CoursesList.vue'),
		meta: {
			resource: resources.ESSAC_COURSES,
			pageTitle: 'Servicios',
			breadcrumb: [{
				text: 'Lista de servicios',
				active: true
			}],
			requiresAuth: true
		}
	},
	{
		path: '/courses/create',
		name: 'courses-create',
		component: () => import('@/views/modules/essac/courses/courses-store/CoursesStore.vue'),
		meta: {
			resource: resources.ESSAC_COURSES,
			action: actions.CREATE,
			pageTitle: 'Servicio',
			breadcrumb: [{
				text: 'Agregar servicio',
				active: true
			}],
			requiresAuth: true
		}
	},
	{
		path: '/courses/:slug/edit',
		name: 'courses-edit',
		component: () => import('@/views/modules/essac/courses/courses-edit/CoursesEdit.vue'),
		meta: {
			resource: resources.ESSAC_COURSES,
			action: actions.UPDATE,
			pageTitle: 'Servicio',
			breadcrumb: [{
				text: 'Lista de servicios',
				to: { name: 'courses-list' },
				active: false
			}, {
				text: 'Editar servicio',
				active: true
			}],
			requiresAuth: true
		}
	},
	{
        path: '/exams/:slug/edit',
        name: 'exams-edit',
        component: () => import('@/views/modules/essac/courses/courses-tabs/tab-exams/exams-edit/ExamsEdit.vue'),
        meta: {
			resource: resources.ESSAC_COURSES,
			action: actions.UPDATE,
            pageTitle: 'Examen',
            breadcrumb: [{
                text: 'Editar examen',
                active: true
            }],
            requiresAuth: true
        }
    }
]
