import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/contracting-companies',
        name: 'contracting-companies-list',
        component: () => import('@/views/modules/essac/contracting-companies/contracting-companies-list/ContractingCompaniesList.vue'),
        meta: {
            resource: resources.ESSAC_CONTRACTING_COMPANIES,
            pageTitle: 'Empresas contratistas',
            breadcrumb: [{
                text: 'Lista de empresas contratistas',
                active: true
            }],
            requiresAuth: true
        }
    }
]