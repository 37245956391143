import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/contractors/reports',
        name: 'contractors-reports',
        component: () => import('@/views/modules/contractors/reports/Reports.vue'),
        meta: {
            resource: resources.CONTRACTOR_REPORTS,
            pageTitle: 'Reportes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true
                }
            ],
            requiresAuth: true
        }
    }
]