import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/contractors',
        name: 'contractors-list',
        component: () => import('@/views/modules/essac/contractors/contractors-list/ContractorsList.vue'),
        meta: {
            resource: resources.ESSAC_CONTRACTING_COMPANIES,
            pageTitle: 'Contratistas',
            breadcrumb: [{
                text: 'Lista de contratistas',
                active: true
            }],
            requiresAuth: true
        }
    }
]