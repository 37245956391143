import { resources } from '@/libs/acl/resources';

export default [
    {
        path: '/supervisory-companies',
        name: 'supervisory-companies-list',
        component: () => import('@/views/modules/essac/supervisory-companies/supervisory-companies-list/SupervisoryCompaniesList.vue'),
        meta: {
            resource: resources.ESSAC_SUPERVISORS_COMPANIES,
            pageTitle: 'Subsidiarias',
            breadcrumb: [{
                text: 'Lista de subsidiarias',
                active: true
            }],
            requiresAuth: true
        }
    }
]
